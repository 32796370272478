import React from "react";
import styles from "./LandingCarousel.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./landing-carousel.css";
export const LandingCarousel = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoPlaySpeed: 2000,
    arrow: false,
    prevArrow: (
      <div className={styles.prevArrow}>
        <div className="next-slick-arrow">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="34"
            viewBox="0 0 20 34"
            fill="none"
          >
            <path
              d="M17 3L3 17L17 31"
              stroke="#013582"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>{" "}
        </div>
      </div>
    ),
    nextArrow: (
      <div className={styles.nextArrow}>
        <div className="prev-slick-arrow">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="34"
            viewBox="0 0 20 34"
            fill="none"
          >
            <path
              d="M3 3L17 17L3 31"
              stroke="#013582"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>{" "}
        </div>
      </div>
    ),
  };
  const carouselData = [
    {
      alt: "The Luxury Chronicle article",
      img: "https://www.ti.com/content/dam/ticom/images/homepage/background/custom/edge-ai-am6xa-platform-homepage-banner.jpg",
      link: "https://www.theluxurychronicle.com/india-disruptor-alert-in-digital-entertainment-halliv-brings-groundbreaking-tech-for-content-creators-and-consumers/",
    },
    // {
    //   alt: "Ani article",
    //   img: news_poster2,
    //   link: "https://aninews.in/news/business/business/new-tech-platform-halliv-gaining-popularity-with-digital-entertainment-creators20230925132603/",
    // },

    {
      alt: "Marksmen Daily Article",
      img: "https://www.ti.com/content/dam/ticom/images/homepage/background/custom/battery-ess-homepage-Banner.jpg",
      link: "https://marksmendaily.com/2023/09/25/new-tech-platform-halliv-gaining-popularity-with-digital-entertainment-creators/",
    },
  ];
  return (
    <div className={`${styles.carousel_container} `}>
      {" "}
      <Slider {...settings} arrows={false}>
        {carouselData?.map((el, i) => {
          return (
            <div className={styles.carousel_slides} key={i}>
              <div className={styles.carousel_slide_item}>
                <div>
                  <h2>Lorem ipsum dolor sit amet consectetur</h2>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Quae, repudiandae dicta non voluptatum
                  </p>
                </div>
                <img
                  src={el?.img}
                  alt={el?.alt}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

import React, { useState } from "react";
import styles from "./ProductCarousel.module.css";
import "./ProductCarousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
import { data } from "../../data";
export const ProductCarousel = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoPlaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div className={styles.prevArrow}>
        <div className="next-slick-arrow">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="34"
            viewBox="0 0 20 34"
            fill="none"
          >
            <path
              d="M17 3L3 17L17 31"
              stroke="#013582"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>{" "}
        </div>
      </div>
    ),
    nextArrow: (
      <div className={styles.nextArrow}>
        <div className="prev-slick-arrow">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="34"
            viewBox="0 0 20 34"
            fill="none"
          >
            <path
              d="M3 3L17 17L3 31"
              stroke="#013582"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>{" "}
        </div>
      </div>
    ),
  };

  return (
    <div className={styles.product_carousel_box}>
      <Slider {...settings}>
        {data?.map((el, i) => {
          return (
            <NavLink
              to={`/products/${el.code}`}
              className={styles.carousel_slides}
            >
              <div
                key={i}
                onMouseOver={() => {
                  setHoveredItem([el.code, el?.images[1]]);
                }}
                onMouseOut={() => {
                  setHoveredItem(null);
                  // Reset or change the image back to the original on mouse out if needed
                }}
              >
                <img
                  src={
                    hoveredItem && hoveredItem[0] == el?.code
                      ? hoveredItem[1]
                      : el?.images[0]
                  }
                  alt=""
                />
                <h3>{el?.item_name}</h3>
                {/* <span>{el.helper_text}</span> */}
                <p style={{ fontWeight: "600" }}>{el?.code}</p>
                <p className={styles.prod_desc}>
                  {el?.product_description?.description[0]}
                </p>
              </div>
            </NavLink>
          );
        })}
      </Slider>
    </div>
  );
};

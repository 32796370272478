import React, { useEffect, useState } from "react";
import styles from "./ProductDetails.module.css";
export const ProductDetails = ({ product }) => {
  const [currentScreen, setCurrentScreen] = useState("parameters");
  const [currentImage, setCurrentImage] = useState(product?.images?.[0]);
  const slugs = [
    {
      title: "Paramters",
      slug: "parameters",
    },
    {
      title: "Features",
      slug: "features",
    },

    {
      title: "Description",
      slug: "desc",
    },
  ];
  const getScreen = (slug) => {
    switch (slug) {
      case "parameters":
        return <Paramters product={product?.product_description?.parameters} />;
      case "size":
        return <Package product={product?.product_description?.package} />;
      case "features":
        return <Features product={product?.product_description?.features} />;
      case "desc":
        return <Desc product={product?.product_description?.description} />;
      default:
        return <Paramters product={product?.product_description?.parameters} />;
    }
  };

  useEffect(() => {
    console.log("product: ", product);
    setCurrentImage(product?.images?.[0]);
  }, [product]);
  return (
    <div className={styles.product_detials_container}>
      <h2>Product details</h2>
      <div className={styles.products_details}>
        <div>
          <div className={styles.product_detials_item}>
            {slugs?.map((el, i) => {
              return (
                <span
                  className={
                    currentScreen == el.slug ? styles.select_screen : ""
                  }
                  key={i}
                  onClick={() => setCurrentScreen(el.slug)}
                >
                  {el.title}
                </span>
              );
            })}
          </div>
          <div className={styles.select_screen_box}>
            {getScreen(currentScreen)}
          </div>
        </div>
        <div className={styles.images_container}>
          <img src={currentImage} alt="" />
          <div>
            {product?.images?.map((el, i) => {
              return (
                <div>
                  <img src={el} onMouseEnter={() => setCurrentImage(el)} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Paramters = ({ product }) => {
  return (
    <div className={styles.parameter_section}>
      {product?.map((el, i) => {
        return (
          <div key={i}>
            <span></span>
            <span></span>
          </div>
        );
      })}
    </div>
  );
};

const Package = ({ product }) => {
  return (
    <div>
      {product?.map((el, i) => {
        return (
          <>
            <div>{el}</div>
            <br />
          </>
        );
      })}
    </div>
  );
};

const Features = ({ product }) => {
  return (
    <div className={styles.features_section}>
      <ul>
        {product?.map((el, i) => {
          return <li>{el}</li>;
        })}
      </ul>
    </div>
  );
};

const Desc = ({ product }) => {
  return (
    <div className={styles.description_box}>
      {product?.map((el, i) => {
        return (
          <>
            <div>{el}</div>
            <br />
          </>
        );
      })}
    </div>
  );
};

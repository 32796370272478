import React from "react";
import styles from "./Category.module.css";

import { NavLink, useParams } from "react-router-dom";
import { data } from "../../data";
import { useState } from "react";
export const Category = () => {
  const { category_slug } = useParams();
  console.log("code: ", category_slug);
  const [hoveredItem, setHoveredItem] = useState(null);
  const category = [
    {
      title: "Single Phase Inverter",
      code: "single_phase_invertor",
    },
    {
      title: "Single Phase Line Interactive Inverter Card",
      code: "single_phase_line_invertor_card",
    },
    {
      title: "Three Phase Inverter Card",
      code: "three_phase_invertor_card",
    },
    {
      title: "Solar Charge Controller PWM Type",
      code: "solar_charge_controller_pwm",
    },
    {
      title: "Solar Charge Controller MPPT Type",
      code: "solar_charge_controller_mppt",
    },
    {
      title: "Supporting Software / Hardware",
      code: "support_software",
    },
    {
      title: "Battery Management System",
      code: "battery_management_system",
    },
  ];
  const categoryTitle = category?.filter((el) => el.code == category_slug);
  const categoryData = data?.filter((el, i) => el.category == category_slug);
  console.log("categoryData: ", categoryData);
  return (
    <div className={styles.favourrites_container}>
      <h2>{categoryTitle[0].title}</h2>
      <div
        className={`${styles.content_list_container} ${styles.product_carousel_box}`}
      >
        {categoryData?.map((el, i) => {
          return (
            <NavLink
              to={`/products/${el.code}`}
              className={styles.carousel_slides}
            >
              <div
                key={i}
                onMouseOver={() => {
                  setHoveredItem([el.code, el?.images[1]]);
                }}
                onMouseOut={() => {
                  setHoveredItem(null);
                  // Reset or change the image back to the original on mouse out if needed
                }}
              >
                <img
                  src={
                    hoveredItem && hoveredItem[0] == el?.code
                      ? hoveredItem[1]
                      : el?.images[0]
                  }
                  alt=""
                />
                <h3>{el?.item_name}</h3>
                {/* <span>{el.helper_text}</span> */}
                <p style={{ fontWeight: "600" }}>{el?.code}</p>
                <p className={styles.prod_desc}>
                  {el?.product_description?.description[0]}
                </p>
              </div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

import img1 from "./Assets/3PhaseInvertor/IMG_20240115_115850.jpg";
import img2 from "./Assets/3PhaseInvertor/IMG_20240115_120040.jpg";
import img3 from "./Assets/3PhaseInvertor/IMG_20240115_120155.jpg";
import img4 from "./Assets/3PhaseInvertor/IMG_20240115_120338.jpg";
import img5 from "./Assets/vs8012/VS8012_Photo1.jpg";
import img6 from "./Assets/vs8012/VS8012_Photo2.jpg";
import img7 from "./Assets/vs8012/VS8012_Photo3.jpg";
import img8 from "./Assets/vs8012/VS8012_Photo4.jpg";
import img9 from "./Assets/VS7596_96V_7.5KVA/VS7596_Photo1.jpg";
import img10 from "./Assets/VS7596_96V_7.5KVA/VS7596_Photo2.jpg";
import img11 from "./Assets/VS7596_96V_7.5KVA/VS7596_Photo3.jpg";
import img12 from "./Assets/VS7512_120V_7.5KVA/VS7512_Photo1.jpg";
import img13 from "./Assets/VS7512_120V_7.5KVA/VS7512_Photo2.jpg";
import img14 from "./Assets/VS7512_120V_7.5KVA/VS7512_Photo3.jpg";
import img15 from "./Assets/VS0524_24V_500VA_AVR/VS0524_Photo.jpg";
import img16 from "./Assets/VS1012_12V_940VA/VS1012_Photo1.jpg";
import img17 from "./Assets/VS1012_12V_940VA/VS1012_Photo2.jpg";
import img18 from "./Assets/VS1012_12V_940VA/VS1012_Photo3.jpg";
import img19 from "./Assets/VS1012_12V_940VA/VS1012_Photo4.jpg";
export let data = [
  {
    images: [img1, img2, img3, img4],
    item_name: "3 Phase Invertor",
    helper_text: "NEW",
    code: "VS3P_72V_6KVA",
    category: "three_phase_invertor_card",
    product_description: {
      parameters: [],
      package: ["VS3P_72V_6KVA", "VS3P_120V_10KVA"],
      features: [
        " 6kVA inverter uses a 6 battery or 36cells 72V dc system.",
        "10kVA inverter uses a 10 battery or 60cells 120V dc system.",
        "Input side (primary side) voltage is 65V.",
        "Leakage inductance on AC side should be close to 5mH.",
      ],
      description: [
        `6kVA inverter uses a 6 battery or 36cells 72V dc system. The ratio N1:N2 is 240:39. Output Voltage
       (secondary side) 240V.`,
        `Input side (primary side) voltage is 39V. Leakage inductance on AC side should 
       be close to 5mH.`,
      ],
    },
    technical_documentation: [
      "The 3-phase inverter is designed to convert DC power to AC power in a three-phase electrical system. It is suitable for a variety of applications where a reliable and efficient power conversion is essential.",
    ],
    design_and_development: [
      "Transformer schematic",
      `6kVa design :  6kVA inverter uses a 6 battery or 36cells 72V dc system. The ratio N1:N2 is 240:39. Output Voltage
      (secondary side) 240V. Input side (primary side) voltage is 39V. Leakage inductance on AC side should 
      be close to 5mH.`,
      ` 10kVA design : 10kVA inverter uses a 10 battery or 60cells 120V dc system. The ratio N1:N2 is 240:65. Output Voltage 
  (secondary side) 240V. Input side (primary side) voltage is 65V. Leakage inductance on AC side should 
  be close to 5mH.`,
    ],
  },
  {
    images: [img5, img6, img7, img8],
    item_name: "Invertor Control Card",
    helper_text: "NEW",
    category: "single_phase_line_invertor_card",
    code: "VS8012_12V_540VA",
    product_description: {
      parameters: [],
      package: [],
      features: [
        "Four Pages of 16 Characters * 2 Lines LCD Display",
        "Each Character in all pages is fully programmable through LCD",
        "Pages rotate 1 by 1. Each page stays for 4 sec & then shift to next page. Data on the page is updated/refreshed at every 1 sec",
        "LCD is fully resetted at 1 minute",
        "Errors/ Buzzer can be re-setted during switch on by switching ON-OFF-ON. This will not turn OFF the system as turning OFF is delayed by 2 sec.",
        `Re-Play Mode: By the time ‘support engineer’ contacts the customer the error has already been 
        cleared by him(customer) & he is unable to give any usable information regarding the fault 
        occurred. Now the last fault recorded in the card can be played any time by simply toggling 
        ON_OFF switch 4 to 7 times in a minute. Turning OFF is delayed by 2 sec. Switch on is also 
        delayed by 1 sec. User has to switch OFF-ON-OFF or ON-OFF-ON 4 to 7 times in a minute to
        listen to last fault. `,
      ],
      description: [
        `The VS8012 is a unique card incorporating many features required for reliable operations of inverters,
        and yet cost effective. `,

        `. Improved reverse phase protection, the DSP controller can detect reverse phase 
        conditions faster and is able to protect the mosfets 99.9% of the times. Tests done for upto 
        1000 reverse phase conditions have passed safely. `,
      ],
    },
    technical_documentation: [
      `The VS8012 inverter control card introduces a host of innovative features focused on enhancing reliability, optimizing battery performance, and improving the user interface. Notably, advanced control schemes mitigate MOSFET failures through improved reverse phase protection and detection of loose battery connections. To address the challenges posed by escalating battery prices, the card incorporates various battery performance optimizations, such as setting float voltage to minimize grid corrosion, implementing boost charging with auto-calibration, and facilitating deep discharge recovery. The system maintains a non-volatile record of discharge and charge cycles, offers a battery water rate reminder, and activates a low battery warning at a specified voltage. Altogether, the VS8012 stands out as a cost-effective solution, incorporating novel design and control techniques to ensure reliable inverter operations while prioritizing user-friendly features and robust protection mechanisms.`,
    ],
    design_and_development: [],
  },
  {
    images: [img9, img10, img11],
    item_name: "Invertor Control Card",
    category: "single_phase_line_invertor_card",
    code: "VS7596_96V_7.5KVA",
    product_description: {
      parameters: [],
      package: [],
      features: [
        "Four Pages of 16 Characters * 2 Lines LCD Display",
        "Each Character in all pages is fully programmable through LCD",
        "Pages rotate 1 by 1. Each page stays for 4 sec & then shift to next page. Data on the page is updated/refreshed at every 1 sec",
        "LCD is fully resetted at 1 minute",
        "Errors/ Buzzer can be re-setted during switch on by switching ON-OFF-ON. This will not turn OFF the system as turning OFF is delayed by 2 sec.",
        `Re-Play Mode: By the time ‘support engineer’ contacts the customer the error has already been 
        cleared by him(customer) & he is unable to give any usable information regarding the fault 
        occurred. Now the last fault recorded in the card can be played any time by simply toggling 
        ON_OFF switch 4 to 7 times in a minute. Turning OFF is delayed by 2 sec. Switch on is also 
        delayed by 1 sec. User has to switch OFF-ON-OFF or ON-OFF-ON 4 to 7 times in a minute to
        listen to last fault. `,
      ],
      description: [
        `The VS8012 is a unique card incorporating many features required for reliable operations of inverters,
        and yet cost effective. `,

        `. Improved reverse phase protection, the DSP controller can detect reverse phase 
        conditions faster and is able to protect the mosfets 99.9% of the times. Tests done for upto 
        1000 reverse phase conditions have passed safely. `,
      ],
    },
    technical_documentation: [
      `The VS8012 inverter control card introduces a host of innovative features focused on enhancing reliability, optimizing battery performance, and improving the user interface. Notably, advanced control schemes mitigate MOSFET failures through improved reverse phase protection and detection of loose battery connections. To address the challenges posed by escalating battery prices, the card incorporates various battery performance optimizations, such as setting float voltage to minimize grid corrosion, implementing boost charging with auto-calibration, and facilitating deep discharge recovery. The system maintains a non-volatile record of discharge and charge cycles, offers a battery water rate reminder, and activates a low battery warning at a specified voltage. Altogether, the VS8012 stands out as a cost-effective solution, incorporating novel design and control techniques to ensure reliable inverter operations while prioritizing user-friendly features and robust protection mechanisms.`,
    ],
    design_and_development: [],
  },
  {
    images: [img12, img13, img14],
    item_name: "Invertor Control Card",
    category: "single_phase_line_invertor_card",
    code: "VS7512_120V_7.5KVA",
    product_description: {
      parameters: [],
      package: [],
      features: [
        "Four Pages of 16 Characters * 2 Lines LCD Display",
        "Each Character in all pages is fully programmable through LCD",
        "Pages rotate 1 by 1. Each page stays for 4 sec & then shift to next page. Data on the page is updated/refreshed at every 1 sec",
        "LCD is fully resetted at 1 minute",
        "Errors/ Buzzer can be re-setted during switch on by switching ON-OFF-ON. This will not turn OFF the system as turning OFF is delayed by 2 sec.",
        `Re-Play Mode: By the time ‘support engineer’ contacts the customer the error has already been 
        cleared by him(customer) & he is unable to give any usable information regarding the fault 
        occurred. Now the last fault recorded in the card can be played any time by simply toggling 
        ON_OFF switch 4 to 7 times in a minute. Turning OFF is delayed by 2 sec. Switch on is also 
        delayed by 1 sec. User has to switch OFF-ON-OFF or ON-OFF-ON 4 to 7 times in a minute to
        listen to last fault. `,
      ],
      description: [
        `The VS8012 is a unique card incorporating many features required for reliable operations of inverters,
        and yet cost effective. `,

        `. Improved reverse phase protection, the DSP controller can detect reverse phase 
        conditions faster and is able to protect the mosfets 99.9% of the times. Tests done for upto 
        1000 reverse phase conditions have passed safely. `,
      ],
    },
    technical_documentation: [
      `The VS8012 inverter control card introduces a host of innovative features focused on enhancing reliability, optimizing battery performance, and improving the user interface. Notably, advanced control schemes mitigate MOSFET failures through improved reverse phase protection and detection of loose battery connections. To address the challenges posed by escalating battery prices, the card incorporates various battery performance optimizations, such as setting float voltage to minimize grid corrosion, implementing boost charging with auto-calibration, and facilitating deep discharge recovery. The system maintains a non-volatile record of discharge and charge cycles, offers a battery water rate reminder, and activates a low battery warning at a specified voltage. Altogether, the VS8012 stands out as a cost-effective solution, incorporating novel design and control techniques to ensure reliable inverter operations while prioritizing user-friendly features and robust protection mechanisms.`,
    ],
    design_and_development: [],
  },
  {
    images: [img15, img15],
    item_name: "Invertor Control Card",
    code: "VS0524_24V_500VA_AVR",
    category: "single_phase_invertor",
    product_description: {
      parameters: [],
      package: [],
      features: [
        " Pure Sinewave output.",
        "Wide in put range with 6 step AVR",
        `5 stage charging system that ensures faster charging and lower battery heating.`,
        `Phase synchronized transfer.`,
        `Ultra fast transfer routine.`,
        `Zero current relay switching for better relay life`,
        `Zero battery drain in off mode.`,
        ` Auto recharge and night guard on mains recovery.`,
        `Fully programmable, all set points and parameters can be programmed by you to give your system the personality 
      specific to your brand.`,

        "Highly fault tolerant system.",
        "Computer communication enabled real time data transfer to a host computer through RS 232c",
      ],
      description: [
        `This is India’s first system that gives you the flexibility of programming the performance of the card as per your 
        requirements without having to know any assembly language.`,
        ` There is an EEPROM in every card that contains 
personality data of the individual card. Just change the data and your product becomes different. This is a high performance single card system that will work even in the remotest corner of India without maintenance 
        headaches.`,
        `The system outputs a pure sine wave that is phase synchronous to the input, when the main fails, the transfer time can 
        be from no break upto 10 ms depending upon phase synchronization and the rate of mains decay`,

        `During mains availability a high performance 6 stage AVR has been incorporated to give you a regulated output for a 
        mains input of 110 V to 275 V. A high performance low ripple charger gives faster charging and longer battery life, 
        charging current of upto 10 Amps can be programmed`,
      ],
    },
    technical_documentation: [
      `This is India’s first system that gives you the flexibility of programming the performance of the card as per your 
        requirements without having to know any assembly language. There is an EEPROM in every card that contains 
        personality data of the individual card. Just change the data and your product becomes different. `,

      `This is a high performance single card system that will work even in the remotest corner of India without maintenance 
        headaches.
        `,
      `The system outputs a pure sine wave that is phase synchronous to the input, when the main fails, the transfer time can 
        be from no break upto 10 ms depending upon phase synchronization and the rate of mains decay`,

      `During mains availability a high performance 6 stage AVR has been incorporated to give you a regulated output for a 
        mains input of 110 V to 275 V. A high performance low ripple charger gives faster charging and longer battery life, 
        charging current of upto 10 Amps can be programmed`,
    ],
    design_and_development: [],
  },
  {
    images: [img16, img17, img18, img19],
    item_name: "LCD Card",
    category: "three_phase_invertor_card",
    code: "VS1012_12V_940VA",
    product_description: {
      parameters: [],
      package: [],
      features: [
        "Four Pages of 16 Characters * 2 Lines LCD Display",
        " Each Character in all pages is fully programmable through LCD",
        `Pages rotate 1 by 1. Each page stays for 4 sec & then shift to next page. Data on the page is 
        updated/refreshed at every 1 sec`,
        `LCD resets every minute`,
        `1 sec on in 1 minute is played for battery water reminder`,
        `No buzzer is played during switch OFF Errors/ Buzzer can be re-setted during switch on by switching ON-OFF-ON. This will not turn 
        OFF the system as turning OFF is delayed by 2 sec.
        `,
      ],
      description: [
        `This is India’s first system that gives you the flexibility of programming the performance of the card as per your 
        requirements without having to know any assembly language. `,
        ,
        `This is a high performance single card system that will work even in the remotest corner of India without maintenance 
        headaches.
        `,
        `The system outputs a pure sine wave that is phase synchronous to the input, when the main fails, the transfer time can 
        be from no break upto 10 ms depending upon phase synchronization and the rate of mains decay`,

        `During mains availability a high performance 6 stage AVR has been incorporated to give you a regulated output for a 
        mains input of 110 V to 275 V. A high performance low ripple charger gives faster charging and longer battery life, 
        charging current of upto 10 Amps can be programmed`,
      ],
    },
    technical_documentation: [
      `The VS/1012 a pure sine wave home UPS card that redefines the price performance ratio.
    Based on Digital Signal Processing (DSP) technology, VIDYUT SYSTEMS VS/1012
    offers a whole new range of features.`,
      ` A patent ALR (Adaptive Loss Reduction) process gives more efficient charging
    system`,
      `An advanced low cost diagnostic tool and calibration system is also available to
    further assist the technician in testing and setting up the various options of the card.
    Due to your continued patronage and our commitment to provide you with the most
    innovative system the VS/1012 is the new benchmark and a world-beater. There is no card
    in the world with these features and specifications, in fact using the VS/1012 you could
    easily make your mark worldwide. Making a reliable system, full of features and yet low cost is a tall order. At Vidyut Systems
    our continuous R&D effort and ability to innovate has led to a new definition that would let
    you achieve higher brand equity with your customers. The VS/1012 has many novel
    concepts.`,
    ],
    design_and_development: [
      `In built SBM (Smart Battery Management) system provides higher degree of battery
      protection and life. Battery usage data is recorded for better evaluation of battery.`,
      ` A patent ALR (Adaptive Loss Reduction) process gives more efficient charging
    system`,
      `An advanced low cost diagnostic tool and calibration system is also available to
    further assist the technician in testing and setting up the various options of the card.
    Due to your continued patronage and our commitment to provide you with the most
    innovative system the VS/1012 is the new benchmark and a world-beater. There is no card
    in the world with these features and specifications, in fact using the VS/1012 you could
    easily make your mark worldwide. Making a reliable system, full of features and yet low cost is a tall order. At Vidyut Systems
    our continuous R&D effort and ability to innovate has led to a new definition that would let
    you achieve higher brand equity with your customers. The VS/1012 has many novel
    concepts.`,
    ],
  },
];

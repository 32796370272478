import React from "react";
import styles from "./Navbar.module.css";
import vidyut_logo from "../../Assets/vidyut_logo.jpg";
import { NavLink } from "react-router-dom";
import profile from "../../Assets/profile.png";
import { data } from "../../data";
export const Navbar = () => {
  // const navItems = [
  //   "Products",
  //   "Applications",
  //   "Design & development",
  //   "Quality & reliability",
  //   "Ordering resources",
  //   "About TI",
  // ];
  const navItems = [
    {
      link: "/",
      title: "Home",
    },

    {
      link: "/about",
      title: "About",
    },
    {
      link: "/category",
      title: "Products",
    },
    {
      link: "/contact_us",
      title: "Help Center",
    },
    {
      link: "/contact_us",
      title: "Contact",
    },
    ,
  ];

  const category = [
    {
      title: "Single Phase Inverter",
      code: "single_phase_invertor",
    },
    {
      title: "Single Phase Line Interactive Inverter Card",
      code: "single_phase_line_invertor_card",
    },
    {
      title: "Three Phase Inverter Card",
      code: "three_phase_invertor_card",
    },
    {
      title: "Solar Charge Controller PWM Type",
      code: "solar_charge_controller_pwm",
    },
    {
      title: "Solar Charge Controller MPPT Type",
      code: "solar_charge_controller_mppt",
    },
    {
      title: "Supporting Software / Hardware",
      code: "support_software",
    },
    {
      title: "Battery Management System",
      code: "battery_management_system",
    },
  ];
  return (
    <>
      <div className={styles.navbar_container}>
        <div className={styles.navbar_img}>
          <NavLink to={"/"}>
            <img src={vidyut_logo} alt="" />
          </NavLink>
        </div>
        <div className={styles.nav_items_box}>
          {navItems?.map((el, i) => {
            return el.title == "Products" ? (
              <div className={styles.dropdown}>
                <NavLink to={el.link}>
                  <span>{el.title}</span>
                </NavLink>
                <div className={styles.dropdown_options}>
                  {category?.map((el, i) => {
                    return (
                      <NavLink to={`/category/${el.code}`} key={i}>
                        <div>{el.title}</div>
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            ) : (
              <NavLink to={el?.link}>
                <span>{el?.title}</span>
              </NavLink>
            );
          })}
        </div>
        <div className={styles.profile_section}>
          <img src={profile} />
        </div>
      </div>
      {/* nav items  */}
      {/* <div className={styles.nav_item_container}>
        {navItems?.map((el, i) => {
          return <span>{el}</span>;
        })}
      </div> */}
    </>
  );
};

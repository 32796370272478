import styles from "./Footer.module.css";
import React from "react";
import vidyut_logo from "../../Assets/vidyut_logo.jpg";
export const Footer = () => {
  return (
    <div className={styles.footer_container} id="ordering-and-quality">
      <div>
        {/* <div>Home</div>
        <div>About</div>
        <div>Contact Us</div> */}
      </div>
      {/* address section */}
      <div>
        <div>
          <h1>MAILING ADDRESS:</h1>
          <h3>D-10/2, Okhla Industrial Area, Phase-2,</h3>
          <h3>New Delhi – 110 020,</h3>
          <h3>India</h3>
          <h3>Phone No: +91 – 011 – 4161 2766 / 2638 8442.</h3>
          <h3>Fax No: +91 – 011 – 4161 2643.</h3>
          <h3>E-mail: info@vidyutsystems.com</h3>
        </div>
        <div>
          <img src={vidyut_logo} alt="" />
        </div>
      </div>
      {/* copyright section */}
      <div>Copyright © 2024 Vidiyut System</div>
    </div>
  );
};

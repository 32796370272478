import React from "react";
import styles from "./About.module.css";
import about_img from "../../Assets/hands-circuit-board.jpg";
export const About = () => {
  return (
    <div className={styles.about_section}>
      <div>
        <div>
          The core team of Vidyut Systems has more than 250 man-years of
          experience in Power Electronics and DSP based systems. We have
          designed, manufactured and commissioned online UPS for mission
          critical application. The products have been running successfully for
          the last 25-30 years.
        </div>
        <div>
          Vidyut Systems offers the above experience and innovative technology
          as a ready to use Power Cards for home UPS and inverters which have
          cutting edge technology, extremely high reliability and field proven
          consistency.
        </div>
        <div>
          Today’s business scenario does not allow an organization to specialise
          in Research and Development, Marketing and Manufacturing at the same
          point of time. Vidyut Systems offers an innovative concept in
          business, that is of equitable alliance. It shares its core competence
          in R&D and Manufacturing to give you cutting edge ready to use Power
          Cards and Inverters, while you use your core competence in Marketing
          and Customer Care to build brand equity of your own.
        </div>
      </div>
      <div>
        <img src={about_img} alt="" />
      </div>
    </div>
  );
};

// Contact.js
import React, { useEffect, useState } from "react";
import styles from "./Contact.module.css";
import emailjs from "@emailjs/browser";

export const Contact = ({ title = "", code = "" }) => {
  const options = {
    publicKey: process.env.REACT_PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "vidyut_systems",
      // Allow 1 request per 10s
      throttle: 1000,
    },
  };
  const [formData, setFormData] = useState({
    email: "",
    message: "",
    phoneNumber: "",
    product_code: code,
    quantity: "",
    subject: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace these with your actual email service, template ID, and user ID
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    console.log("serviceId: ", serviceId);
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    console.log("templateId: ", templateId);

    try {
      await emailjs.send(serviceId, templateId, formData, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      });

      // Clear form data after successful submission
      setFormData({
        email: "",
        phoneNumber: "",
        message: "",
        product_code: "",
        quantity: "",
        subject: "",
      });

      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  useEffect(() => {
    emailjs.init(options);
  }, []);

  return (
    <div className={styles.contact_container}>
      <h1>{title}</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Your Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Phone Number</label>
          <input
            type="number"
            className={styles.no_spin}
            name="phoneNumber"
            maxLength={"10"}
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Subject:</label>
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label>Message:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            rows={5}
            required
          ></textarea>
        </div>
        <div>
          <label>Product Code:</label>
          <input
            type="text"
            name="product_code"
            value={formData.product_code}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Quantity:</label>
          <input
            type="number"
            name="quantity"
            className={styles.no_spin}
            min={1}
            required
            value={formData.quantity}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

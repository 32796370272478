import React from "react";
import { data } from "../../data";
import styles from "./Category.module.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";
export const AllCategories = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const groupedProducts = data.reduce((acc, product) => {
    const { category, ...rest } = product;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(rest);
    return acc;
  }, {});

  // Convert the grouped products back to an array of objects
  const groupedProductsArray = Object.keys(groupedProducts).map((category) => ({
    category,
    products: groupedProducts[category],
  }));
  return (
    <div className={styles.all_catogries_container}>
      <h2>RANGE OF OUR PRODUCTS</h2>
      {groupedProductsArray?.map((el, i) => {
        return (
          <div
            className={`${styles.favourrites_container} ${styles.category_container}`}
          >
            <h2>{el.category}</h2>
            <div
              className={`${styles.content_list_container} ${styles.product_carousel_box}`}
            >
              {el.products?.map((el, i) => {
                return (
                  <NavLink
                    to={`/products/${el.code}`}
                    className={styles.carousel_slides}
                  >
                    <div
                      key={i}
                      onMouseOver={() => {
                        setHoveredItem([el.code, el?.images[1]]);
                      }}
                      onMouseOut={() => {
                        setHoveredItem(null);
                        // Reset or change the image back to the original on mouse out if needed
                      }}
                    >
                      <img
                        src={
                          hoveredItem && hoveredItem[0] == el?.code
                            ? hoveredItem[1]
                            : el?.images[0]
                        }
                        alt=""
                      />
                      <h3>{el?.item_name}</h3>
                      {/* <span>{el.helper_text}</span> */}
                      <p style={{ fontWeight: "600" }}>{el?.code}</p>
                      <p className={styles.prod_desc}>
                        {el?.product_description?.description[0]}
                      </p>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

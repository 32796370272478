import React from "react";
import { LandingCarousel } from "../../Components/Carousel/LandingCarousel";
import styles from "./Home.module.css";
import { ProductCarousel } from "../../Components/ProductCarousel/ProductCarousel";
import { VideoComp } from "../../Components/Home/VideoComp";
import { NavLink } from "react-router-dom";
const Home = () => {
  const homeItems = [
    "Get support",
    "Find reference designs",
    "Design & development",
    "Cross-reference search",
  ];

  const category = [
    {
      title: "Single Phase Inverter",
      code: "single_phase_invertor",
    },
    {
      title: "Single Phase Line Interactive Inverter Card",
      code: "single_phase_line_invertor_card",
    },
    {
      title: "Three Phase Inverter Card",
      code: "three_phase_invertor_card",
    },
    {
      title: "Solar Charge Controller PWM Type",
      code: "solar_charge_controller_pwm",
    },
    {
      title: "Solar Charge Controller MPPT Type",
      code: "solar_charge_controller_mppt",
    },
    {
      title: "Supporting Software / Hardware",
      code: "support_software",
    },
    {
      title: "Battery Management System",
      code: "battery_management_system",
    },
  ];
  return (
    <div>
      {/* video comp */}
      <VideoComp />
      {/* <LandingCarousel /> */}
      {/* home items */}
      {/* <div className={styles.home_item_container}>
        {homeItems?.map((el, i) => {
          return <span key={i}>{el}</span>;
        })}
      </div> */}

      {/* range of product */}
      <div className={`${styles.new_products} ${styles.products_range}`}>
        <h2>RANGE OF OUR PRODUCTS</h2>
        <div>
          {category?.map((el, i) => {
            return (
              <NavLink to={`/category/${el.code}`}>
                <div>{el.title}</div>
                <span></span>
              </NavLink>
            );
          })}
        </div>
      </div>

      {/* new products  */}
      <div className={styles.new_products}>
        <h2>NEW PRODUCTS</h2>
        <ProductCarousel />
      </div>

      {/* our passion container */}
      <div className={styles.passion_container}>
        <div>Our passion</div>
        <div>
          <p>
            Vidyut Systems, An Ideal technology Partner, Offers a unique and
            innovative concept in business that is of equitable alliance. It
            shares 35 years of its core competence in R&D and manufacturing in
            power electronics, to give you ’ready to use’ power cards. As
            today’s business scenario does not allow SME to specialize in R&D,
            marketing and manufacturing, all at the same time. Here is a
            business model where you use your core competence in marketing and
            customer care along with our technical competence to build brand
            equity of your own.
          </p>
          <div>
            Our Products find application in various sectors such as Household,
            Hospitality, Health care, Petrol station, Lifestyle Outlets,
            Telecommunication, Solar and Wind energy etc.
          </div>
          {/* <div>
            <p>What to do</p>
            <p>Who we are</p>
          </div> */}
        </div>
      </div>

      {/* our commitments  */}
      {/* <div className={styles.commitment_box}>
        <h2>Our Commitment to corporate citizenship</h2>
        <div>
          <p>
            <span>90%</span>
            Percentage of shipped semiconductor products considered green that
            meet low-halogen industry requirements.
          </p>
          <p>
            <span>90%</span>
            Percentage of shipped semiconductor products considered green that
            meet low-halogen industry requirements.
          </p>

          <p>
            <span>90%</span>
            Percentage of shipped semiconductor products considered green that
            meet low-halogen industry requirements.
          </p>
          <p>
            <span>90%</span>
            Percentage of shipped semiconductor products considered green that
            meet low-halogen industry requirements.
          </p>
          <p>
            <span>90%</span>
            Percentage of shipped semiconductor products considered green that
            meet low-halogen industry requirements.
          </p>
          <p>
            <span>$52 million</span> of philanthropic donations, in partnership
            with the TI Foundation, TI employees and retirees, to support our
            local communities, including more than $14 million for education.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Home;

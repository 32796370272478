import React from "react";
import styles from "./VideoComp.module.css";
import home_video from "../../Assets/video (2160p).mp4";
export const VideoComp = () => {
  return (
    <div className={styles.video_container}>
      <video muted autoPlay loop src={home_video}></video>
      <div className={styles.home_text_box}>
        <h1>Welcome to Vidyut system</h1>
        <h2>
          Vidyut Systems, An Ideal technology Partner, Offers a unique and
          innovative concept in business that is of equitable alliance.{" "}
        </h2>
      </div>
    </div>
  );
};

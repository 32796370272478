import React from "react";
import styles from "./Technical.module.css";
export const TechnicalDocumentation = ({ product }) => {
  return (
    <div className={styles.technical_documentation}>
      <h2>Technical Documentation</h2>
      {product?.technical_documentation?.map((el, i) => {
        return <div>{el}</div>;
      })}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import styles from "./Products.module.css";
import { useParams } from "react-router-dom";
import { TechnicalDocumentation } from "../../Components/Technical Documentation/TechnicalDocumentation";
import { Order } from "../../Components/Ordering/Order";
import { data } from "../../data";
import { DesignAndDevelopment } from "../../Components/DesignDevelopment/DesignAndDevelopment";
import { Contact } from "../../Components/Contact Us/Contact";
import { ProductDetails } from "../../Components/ProductDetails/Productdetails";
export const Products = () => {
  const { code } = useParams();
  const [currentProduct, setCurrentProduct] = useState(
    data?.filter((el) => el.code == code)
  );
  const productNav = [
    { title: "Product details", term: "details" },
    { title: "Technical documentation", term: "technical-documentation" },
    { title: "Design and development", term: "design-and-development" },
    { title: "Ordering and quantity", term: "ordering-and-quality" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentProduct = data?.filter((el) => el.code == code);
    setCurrentProduct(currentProduct[0]);
  }, [code]);
  return (
    <div className={styles.product_des_container}>
      {/*  */}
      <div>
        <div>
          <span>NEW</span>
          <h1>{currentProduct?.code}</h1>
          <p>{currentProduct?.item_name}</p>
        </div>
        <div>
          <a style={{ color: "#fff" }} href="#ordering-and-quality">
            <button className={styles.order_btn}> Order</button>
          </a>
        </div>
      </div>
      {/* sticky navbar */}
      <nav className={styles.product_desc_nav}>
        {productNav?.map((el, i) => {
          return (
            <a href={`#${el.term}`} key={i}>
              {el.title}
            </a>
          );
        })}
      </nav>
      {/* detials */}
      <div id="details" style={{ paddingTop: "70px" }}>
        <ProductDetails product={currentProduct} />
      </div>
      <div id="technical-documentation" style={{ paddingTop: "70px" }}>
        <TechnicalDocumentation product={currentProduct} />
      </div>
      <div id="design-and-development" style={{ paddingTop: "70px" }}>
        <DesignAndDevelopment product={currentProduct} />
      </div>
      <div id="ordering-and-quality" style={{ paddingTop: "70px" }}>
        <Contact code={currentProduct.code} title="Ordering and quantity" />
      </div>
    </div>
  );
};

import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import { Products } from "./Products/Products";
import { About } from "./About/About";
import { Contact } from "../Components/Contact Us/Contact";
import { HomeV1 } from "./Home/HomeV1";
import { Category } from "./Category/Category";
import { AllCategories } from "./Category/AllCategories";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products/:code" element={<Products />} />
      <Route path="/category/:category_slug" element={<Category />} />
      <Route path="/category" element={<AllCategories />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact_us" element={<Contact title="Contact Us 🌻" />} />
    </Routes>
  );
};

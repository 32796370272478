import React from "react";
import styles from "./DesignAndDevelopment.module.css";
export const DesignAndDevelopment = ({ product }) => {
  return (
    <div className={styles.technical_documentation}>
      <h2>Design And Development</h2>
      {product?.design_and_development?.map((el, i) => {
        return <div key={i}>{el}</div>;
      })}
    </div>
  );
};
